/* You can add global styles to this file, and also import other style files */
// $gutter: 1rem; //for primeflex grid system
// @import "layout/layout.scss";

// /* PrimeNG */
// @import "../../node_modules/primeng/resources/primeng.min.css";
// @import "../../node_modules/primeflex/primeflex.css";
// @import "../../node_modules/primeicons/primeicons.css";

// /* Code Highlight */
// @import "../../node_modules/prismjs/themes/prism-coy.css";


// @import '~bootstrap/dist/css/bootstrap.min.css';
// @import '~bs-stepper/dist/css/bs-stepper.min.css';

/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* PrimeNG */
@import "../../node_modules/primeng/resources/primeng.min.css";
@import "../../node_modules/primeflex/primeflex.scss";
@import "../../node_modules/primeicons/primeicons.css";

/* Layout */
@import "../assets/styles/layout/layout.scss";
@import "../assets/styles/layout/preloading.scss";

:host ::ng-deep .p-toast {
  z-index: 99999;
}

p-tabView {
  ul.p-tabview-nav {
    li {
      a {
        &:focus {
          background-color: rgba(63, 81, 181, 0.12);
          border-radius: 0;
          border-bottom-width: 3px;
        }
      }
    }
  }
}

@media all and (max-width: 991px) {
  .layout-topbar-mobile {
    display: inline-flex !important;
  }

  .layout-topbar-desktop {
    display: none !important;
  }
}

@media all and (min-width: 992px) {
  .layout-topbar-mobile {
    display: none !important;
  }

  .layout-topbar-desktop:not(.p-avatar) {
    display: block !important;
  }
}

.p-paginator-current {
  width: 100% !important;
}

@media (max-width: 640px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

@media (min-width: 641px) and (max-width: 767px) {
  .col-sm-hidden {
    display: none !important;
  }
}

@media (min-width: 641px) and (max-width: 991px) {
  .col-md-hidden {
    display: none !important;
  }
}

.p-button {
  border-radius: 6px;
}

.p-datatable-wrapper {

  .sorting_ASC,
  .sorting_DESC,
  .sorting {
    cursor: pointer;
  }

  .p-datatable-thead>tr>th {
    padding: 1rem .25rem;
  }

  .p-datatable-tbody>tr>td {
    word-break: break-all;
    padding: 1rem .25rem;
  }
}

.p-confirm-dialog-message {
  width: 100%;

  .table {
    width: 100%;
  }
}

.validation-form .p-error {
  padding-left: 4px;
  padding-top: 3px;
}

.numberman-page .p-fileupload.p-component {
  float: left !important;
}

.layout-main {
  position: relative;
}

// .p-button-back {
//   position: absolute !important;
//   top: 163px;
//   right: 70px;
// }
p-fieldset {
  fieldset {
    margin-bottom: 20px !important;
  }
}

.field .p-component-full {
  width: 100%;

  .p-dropdown.p-component {
    width: 100%
  }
}

.billing_method_component {

  input,
  button {
    height: 44px !important;
  }
}

@media (max-width: 640px) {
  .p-datatable-responsive {
    .p-datatable-tbody tr {
      border-bottom: 1px dashed;

      td:first-child {
        padding-top: 2rem;
      }

      td:last-child {
        padding-bottom: 2rem;
      }

      td {
        vertical-align: middle;
      }
    }
  }
}

.page_title {
  padding-bottom: 1rem;
  padding-left: 0.25rem;

  small {
    font-size: 12px;
  }
}

.layout-theme-dark {
  .p-warning-text {
    color: var(--yellow-300)
  }
}

.layout-theme-light {
  .p-warning-text {
    color: var(--yellow-700)
  }
}

.p-blockui-document.p-component-overlay {
  background: var(--text-color);
  opacity: .35
}

.field.StripeElement {
  border: 1px solid var(--text-color-secondary) !important;
  border-radius: 2px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-datatable .p-datatable-thead>tr {
  th {
    a {
      color: var(--text-color) !important;
      cursor: pointer;
    }
  }

  th.sorting::after,
  th.sorting_asc::after,
  th.sorting_desc::after {
    font-family: primeicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 11px;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    margin-left: 4px;
  }

  th.sorting::after {
    content: "\e99e";
  }

  th.sorting_asc::after {
    content: "\e99c";
  }

  th.sorting_desc::after {
    content: "\e99d";
  }
}

p-tabview {
  .p-tabview-nav-container {
    ul {
      li {
        margin-bottom: 1px;
      }
    }
  }
}

.p_spam_button span.p-button-label {
  word-break: break-word !important;
  text-align: left !important;
}

.country_flag {
  width: 20px;
  margin-right: 4px;
}

.page_title {
  padding-top: 2rem;
}

.card-toggle .p-button.p-highlight {
  background: #A5D6A7 !important;
  color: #000 !important;
}

p-inputnumber.p-inputwrapper {
  min-height: 43px !important;
  max-height: 45px !important;
}

// while loader is running
.pace-running .block-ui-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.pace.pace-inactive .splash-screen {
  display: none;
}

@media screen and (min-width: 992px) {
  .layout-container.layout-static .layout-content-wrapper {
    margin-left: 16rem;
  }

  .layout-container.layout-static-inactive .layout-content-wrapper {
    margin-left: 0;
  }

  .layout-container.layout-static .layout-sidebar {
    width: 16rem;
  }

  .layout-container.layout-overlay.layout-overlay-active .layout-sidebar {
    width: 16rem;
  }

}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.layout-sidebar .layout-menu-container {
  direction: rtl;
}

.layout-menu {
  direction: ltr;
}